import React from 'react'
import * as styles from './styles.module.scss'

const Arrow = () => {
  return (
    <span
      className={`${styles.arrow} ${styles.arrowBar} ${styles.isRight}`}
    ></span>
  )
}

export default Arrow
