import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import LineLink from 'components/LineLink'
import ButtonLink from 'components/ButtonLink'
import Social from 'components/Social'
import Inview from 'components/Inview'
import { LocaleContext } from 'gatsby-theme-i18n'
import * as styles from './styles.module.scss'

const Component = ({ data, layoutProps }) => {
  const locale = React.useContext(LocaleContext)
  const { isMenuOpen, toggleIsMenuOpen } = layoutProps
  const menuOpenClass = isMenuOpen ? styles.menuOpen : ''

  let menu = data.menus.nodes[0].frontmatter[locale]
  let pages = data.pages.nodes
  pages = pages.map((page) => page.frontmatter[locale])
  menu.main.forEach((item) => {
    item.data = pages.find((page) => page.slug === item.page)
    item.submenu?.forEach((subitem) => {
      subitem.data = pages.find((page) => page.slug === subitem.page)
    })
  })
  const lastItemStagger = (menu.main.length + 1) * 50 + 100

  return (
    <>
      <div
        className={`${styles.overlay} ${menuOpenClass}`}
        onClick={toggleIsMenuOpen}
      />
      <Inview className={`${styles.menu} ${menuOpenClass}`}>
        <div className={styles.inner}>
          <div className={styles.upper}>
            <div>
              <div>
                {menu.main?.map((item, i) => (
                  <M0 content={item} key={i} i={i} />
                ))}
              </div>
              <div
                className={`${styles.social} fade-in up stagger-${lastItemStagger}`}
              >
                <Social />
              </div>
            </div>
          </div>
          <div
            className={`${styles.lanWrap} fade-in up stagger-${lastItemStagger}`}
          >
            <Lan />
          </div>
        </div>
      </Inview>
    </>
  )
}

const Lan = () => {
  const locale = React.useContext(LocaleContext)
  return (
    <div className={styles.lan}>
      <div className={styles.inner}>
        {locale === 'zh' && (
          <ButtonLink
            content={{
              text: 'EN',
              to: '/en',
              linkType: 'page',
              bypassLocalization: true,
              bgColor: 'orange',
            }}
          />
        )}
        {locale === 'en' && (
          <ButtonLink
            content={{
              text: '中文',
              to: '/',
              linkType: 'page',
              bypassLocalization: true,
              bgColor: 'orange',
            }}
          />
        )}
      </div>
    </div>
  )
}
const M0 = ({ content, i }) => {
  const { submenu, data } = content
  const { title, path } = data
  return (
    <div className={`${styles.m0} fade-in up stagger-${i * 50 + 100}`}>
      <h4>
        <LineLink
          content={{ text: title, to: path, linkType: 'page' }}
          customStyle='m0'
        />
      </h4>
      {submenu && (
        <div className={styles.submenu}>
          {submenu.map((item, i) => (
            <M1 content={item} key={i} />
          ))}
        </div>
      )}
    </div>
  )
}

const M1 = ({ content }) => {
  const { data } = content
  const { title, path } = data
  return (
    <div className={styles.m1}>
      <p>
        <LineLink
          content={{ text: title, to: path, linkType: 'page' }}
          customStyle='m1'
          activeClassName='active'
        />
      </p>
    </div>
  )
}

export default function Data({ layoutProps }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          menus: allMarkdownRemark(
            filter: {
              frontmatter: {
                en: { templateKey: { eq: "menu" }, slug: { eq: "primary" } }
              }
            }
          ) {
            nodes {
              frontmatter {
                en {
                  title
                  main {
                    page
                    submenu {
                      page
                    }
                  }
                }
                zh {
                  title
                  main {
                    page
                    submenu {
                      page
                    }
                  }
                }
              }
            }
          }
          pages: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "page" } } } }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  path
                }
                zh {
                  slug
                  title
                  path
                }
              }
            }
          }
        }
      `}
      render={(data) => <Component data={data} layoutProps={layoutProps} />}
    />
  )
}
