import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Arrow from 'components/Arrow'
import EmailSvg from 'assets/svg/icons/email.svg'
import * as styles from './styles.module.scss'

const ButtonLink = ({ content }) => {
  const {
    to,
    text,
    linkType,
    isDisabled,
    bypassLocalization,
    bgColor = '',
  } = content || {}
  const bgColorClass = bgColor ? styles[bgColor] : ''

  if (to && linkType === 'email') {
    return (
      <a
        href={`mailto:${to}`}
        className={`${styles.button} ${styles.email} ${bgColorClass}`}
      >
        <EmailSvg />
        <Inner text={text} isEmail={true} />
      </a>
    )
  } else if (to && linkType === 'external') {
    return (
      <a
        href={to}
        target='_blank'
        className={`${styles.button} ${bgColorClass}`}
      >
        <Inner text={text} />
      </a>
    )
  } else if (linkType === 'submit') {
    return (
      <button
        className={`${styles.button} ${bgColorClass}`}
        type='submit'
        disabled={isDisabled}
      >
        <Inner text={text} />
      </button>
    )
  } else if (to) {
    return (
      <LinkWrap
        to={to}
        bypassLocalization={bypassLocalization}
        className={`${styles.button} ${bgColorClass}`}
      >
        <Inner text={text} />
      </LinkWrap>
    )
  } else {
    return (
      <div className={`${styles.button} ${bgColorClass}`}>
        <Inner text={text} />
      </div>
    )
  }
}
export default ButtonLink

const Inner = ({ text, direction = 'right', isEmail }) => {
  return (
    <>
      <span className={styles.root}>{text}</span>
      <span className={styles.mask}>
        <span className={styles.hover}>
          {isEmail && <EmailSvg />}
          {text}
          <span className={styles.arrowWrap}>
            <Arrow />
          </span>
        </span>
      </span>
    </>
  )
}
