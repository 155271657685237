import React from 'react'
import LinkWrap from 'components/LinkWrap'
import GlobeSvg from 'assets/svg/icons/globe.svg'
import * as styles from './styles.module.scss'

const LineLink = ({ content, customStyle = '', addGlobeIcon = false }) => {
  const { to, text, linkType } = content || {}

  if (to && linkType === 'email') {
    return (
      <a
        href={`mailto:${to}`}
        className={`${styles.component} ${styles[customStyle]}`}
      >
        <span>{text}</span>
      </a>
    )
  } else if (to && linkType === 'external') {
    return (
      <a
        href={to}
        target='_blank'
        className={`${styles.component} ${styles[customStyle]}`}
      >
        {addGlobeIcon && <GlobeSvg />}
        <span>{text}</span>
      </a>
    )
  } else if (to) {
    return (
      <LinkWrap
        to={to}
        className={`${styles.component} ${styles[customStyle]}`}
        activeClassName={styles.active}
      >
        <span>{text}</span>
      </LinkWrap>
    )
  } else {
    return (
      <span className={`${styles.component} ${styles[customStyle]}`}>
        <span>{text}</span>
      </span>
    )
  }
}

export default LineLink
