import React from 'react'
import Link from 'gatsby-link'
import { LocaleContext } from 'gatsby-theme-i18n'
import { lanLinks } from 'js/translations.js'

const LinkWrap = ({
  to,
  className = '',
  activeClassName = '',
  children,
  bypassLocalization,
}) => {
  const locale = React.useContext(LocaleContext)
  const thisLanLink = lanLinks[locale]
  let setTo = to
  if (!bypassLocalization) setTo = thisLanLink.urlBase + to
  if (setTo === '/en/') setTo = '/en' // logo
  if (setTo) {
    return (
      <Link to={setTo} className={className} activeClassName={activeClassName}>
        {children}
      </Link>
    )
  } else {
    return <a className={`empty ${className}`}>{children}</a>
  }
}

export default LinkWrap
