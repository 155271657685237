import React, { useState, useEffect, useRef } from 'react'
import { globalHistory } from '@reach/router'
import Header from 'components/Header'
import Menu from 'components/Menu'
import * as styles from './styles.module.scss'

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isHeaderUp, setIsHeaderUp] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(false)

  const toggleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const lastScrollY = useRef()

  const processIsDarkMode = (bool) => {
    if (bool) {
      setTimeout(() => setIsDarkMode(true), 1)
    }
    if (!bool) {
      setIsDarkMode(false)
    }
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setIsMenuOpen(false)
        processIsDarkMode(false)
      }
    })
  }, [setIsMenuOpen, processIsDarkMode])

  useEffect(() => {
    window.addEventListener('scroll', smartMenu, { passive: true })
    return () => window.removeEventListener('scroll', smartMenu)
  }, [])

  const smartMenu = () => {
    const scrollDistance = window.scrollY - lastScrollY.current
    const hitBottom =
      window.scrollY + window.innerHeight >
      document.querySelector('body').offsetHeight - 10
    if (window.scrollY > 400 && scrollDistance > 0 && !hitBottom) {
      setIsHeaderUp(true)
    } else {
      setIsHeaderUp(false)
    }
    lastScrollY.current = window.scrollY
  }

  const layoutProps = {
    isMenuOpen,
    toggleIsMenuOpen,
    isHeaderUp,
    isDarkMode,
    processIsDarkMode,
  }
  const isDarkModeClass = isDarkMode ? styles.isDarkMode : ''

  return (
    <div className={`${styles.layout} ${isDarkModeClass}`}>
      <Header layoutProps={layoutProps} />
      <Menu layoutProps={layoutProps} />
      {React.cloneElement(children, { layoutProps })}
    </div>
  )
}

export default Layout
