import React from 'react'
import IconWechat from 'assets/svg/social/wechat.svg'
import IconLinkedin from 'assets/svg/social/linkedin.svg'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from './styles.module.scss'

const Social = ({ version = 'light', isLeft = false }) => {
  const versionClass =
    version === 'dark' ? styles.versionDark : styles.versionLight
  const isLeftClass = isLeft ? styles.isLeft : ''
  return (
    <div className={`${versionClass} ${isLeftClass}`}>
      <div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <IconWechat />
          </div>
          <div className={styles.qr}>
            <StaticImage src='../../assets/images/qr-wechat.png' alt='' />
          </div>
        </div>
        <a
          className={styles.item}
          target='_blank'
          href='https://www.linkedin.com/company/prohr-international'
        >
          <div className={styles.icon}>
            <IconLinkedin />
          </div>
        </a>
      </div>
    </div>
  )
}

export default Social
