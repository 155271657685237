import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Logo from 'assets/svg/vi/logo.svg'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ layoutProps }) => {
  const { isMenuOpen, toggleIsMenuOpen, isHeaderUp } = layoutProps
  const menuOpenClass = isMenuOpen ? styles.menuOpen : ''
  const headerUpClass = isHeaderUp ? styles.headerUp : ''
  return (
    <div className={`${styles.header} ${headerUpClass} ${menuOpenClass}`}>
      <Inview className={`${styles.inner} fade-in stagger-3500`}>
        <LinkWrap to='/' className={styles.logo}>
          <Logo />
        </LinkWrap>
        <div
          className={`${styles.thumb} ${menuOpenClass} fade-in up`}
          onClick={toggleIsMenuOpen}
        >
          <span />
          <span />
          <span />
        </div>
      </Inview>
    </div>
  )
}

export default Component
